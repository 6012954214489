import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from './AspectRatioContainer';
import OriginalInternalLink from './InternalLink';
import WPImage from './WPImage';

const InternalLink = styled(OriginalInternalLink)`
  margin-right: 0.3rem;
`;

const Title = styled.h3`
  font-family: ffvCorporateFat, sans-serif;
  line-height: 1.3;
  font-size: 1rem;
  text-align: left;
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  color: ${props => (props.theme.black ? 'white' : 'black')};
  @media all and (max-width: 700px) {
    font-size: 1.2rem;
  }
`;

export default ({ id, src, srcSet, sizes, title, categories, aspectRatio }) => (
  <>
    <Link to={`/projects/${id}`}>
      <AspectRatioContainer aspectRatio={aspectRatio || 1.5}>
        <WPImage src={src || ''} srcSet={srcSet} sizes={sizes} cover />
      </AspectRatioContainer>
      <Title>{title}</Title>
    </Link>
    {categories.map((category, i, arr) => (
      <InternalLink key={category + i} small noUnderline noHover noPointer>
        {`${category}${i < arr.length - 1 ? ',' : ''}`}
      </InternalLink>
    ))}
  </>
);
