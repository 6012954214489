import React, { useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import styled, { keyframes } from 'styled-components';

import SoundToggle from './SoundToggle';
import Video from './Video';
import { isMobile } from 'react-device-detect';

const FullScreenVideo = styled(Video)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    width: 177.77777778vh;
    min-width: 100%;
    min-height: 56.25vw;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 81;
  overflow: hidden;
`;

const jump = keyframes`
  0% {
    padding-bottom: 1.5rem;
  }

  90% {
    padding-bottom: 1.5rem;
  }

  95% {
    padding-bottom: 2rem;
  }
  100% {
    padding-bottom: 1.5rem;
  }
`;

const DownArrow = styled.div`
  position: absolute;
  width: 100%;
  padding-bottom: 1.5rem;
  bottom: 0;
  left: 0;
  text-align: center;
  animation: 4s ${jump} ease-in infinite;
  & svg {
    width: 3rem;
  }
`;

// Pushes Content down below Stages
const ContentSpacer = styled.div`
  height: 100vh;
  height: calc(100vh - 2rem);
`;

export default handleViewport(
  ({ data: { desktop_video, mobile_video }, inViewport, innerRef }) => {
    const [muted, setMuted] = useState(true);
    const type = isMobile ? mobile_video : desktop_video;

    const switchSound = e => {
      e.preventDefault();
      setMuted(prev => !prev);
    };

    useEffect(() => {
      if (muted || !inViewport) {
        setMuted(true);
      }
    }, [muted, setMuted, inViewport]);

    return (
      <>
        <Wrapper ref={innerRef}>
          <FullScreenVideo
            autoPlay
            muted={muted}
            loop
            hd={type.video_link}
            sd={type.video_link}
            overlay={type.fade_over_black}
            overlayBackground={type.fallback_image.url}
            isIndexVideo
          />
          <DownArrow>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3rem"
              height="3rem"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
              />
              <path fill="none" d="M0 0h24v24H0V0z" />
            </svg>
          </DownArrow>
          <SoundToggle muted={muted} switchSound={switchSound} />
        </Wrapper>
        <ContentSpacer />
      </>
    );
  }
);
