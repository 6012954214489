import React from 'react';

import Content from '../Content';
import IndexVideo from '../IndexVideo';
import Layout from '../Layout';
import SelectedWork from '../SelectedWork';

export default ({
  pageContext: { searchIndex, index, stages, filteredMeta },
}) => {
  return (
    <>
      <Layout background={false} searchIndex={searchIndex} startPage={true}>
        <IndexVideo data={index.acf.fullscreen_video} />
        <Content content={index.acf.content} />
        <SelectedWork data={filteredMeta} />
      </Layout>
    </>
  );
};
