import { Grid } from '@material-ui/core';
import React from 'react';

import ProjectCard from './ProjectCard';
import Spacer from './Spacer';
import Subheading from './Subheading';

export default ({ data }) =>
  data && (
    <Grid container justify="center" spacing={24}>
      <Grid item xs={12}>
        <Spacer space="5rem" />
        <Subheading
          style={{
            marginBottom: '-0.2rem',
          }}
        >
          Selected Work
        </Subheading>
      </Grid>
      {data.map((el, i) => (
        <Grid item xs={10} sm={4} key={el.ID}>
          <ProjectCard
            id={el.ID}
            src={el.featured_image || ''}
            title={el.title}
            categories={el.metadata.categories}
          />
        </Grid>
      ))}
    </Grid>
  );
